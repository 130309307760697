import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import Container from '../components/Container';
import Text from '../components/Text';
import config from '../config';

const hoursOfOperation = [
  {
    title: 'Monday - Friday',
    hours: '5:30am to 11pm',
  },
  {
    title: 'Saturday',
    hours: '8am to 6pm',
  },

  {
    title: 'Sunday',
    hours: '9am to 5pm',
  },
];

const Content = styled.div`
  height: 100vh;
  width: 100%;
`;

const AboutSection = styled.div`
  padding: ${props => (props.noPadding ? 0 : '1rem 0')};
  > h2,
  h3 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0;
  }
`;

const CleanList = styled.ul`
  list-style: none;

  a {
    text-decoration: none;
  }

  h3 {
    margin-bottom: 0.5rem;
  }

  span {
    margin-bottom: 1rem;
  }
`;

const About = () => {
  return (
    <>
      <Helmet>
        <title>Excel Fitness Club | Contact</title>
      </Helmet>
      <Container>
        <Content>
          <AboutSection noPadding={true}>
            <Text as="h1" size="x-large" weight="bold">
              Contact
            </Text>
            <CleanList>
              <li>
                <Text as="h3" size="medium" weight="bold">
                  Telephone
                </Text>
                <Text
                  as="span"
                  size="small"
                  color={config.colors.alt}
                  italic={true}
                >
                  905-492-6277
                </Text>
              </li>
              <li>
                <Text as="h3" size="medium" weight="bold">
                  Email
                </Text>
                <a href="mailto:excelfitnessclub@yahoo.ca">
                  <Text
                    as="span"
                    size="small"
                    color={config.colors.alt}
                    italic={true}
                  >
                    excelfitnessclub at yahoo dot ca
                  </Text>
                </a>
              </li>
            </CleanList>
          </AboutSection>
          <AboutSection>
            <Text as="h2" size="large" weight="bold">
              Locate Us
            </Text>
            <Text as="h3" size="medium" weight="bold">
              1050 Brock Road (Unit 9), Pickering, ON
            </Text>
            <Text as="p" size="medium" italic={true} color={config.colors.alt}>
              We are located just south of the 401, exiting on Brock Rd. (Exit
              399). It will be located on your right hand side driving south
              (beside Country Style).
            </Text>
          </AboutSection>
          <AboutSection>
            <Text as="h2" size="large" weight="bold">
              Hours Of Operation
            </Text>
            <CleanList>
              {hoursOfOperation.map(item => (
                <li key={item.title}>
                  <Text as="h3" size="medium" weight="bold">
                    {item.title}
                  </Text>
                  <Text
                    as="span"
                    size="small"
                    color={config.colors.alt}
                    italic={true}
                  >
                    {item.hours}
                  </Text>
                </li>
              ))}
            </CleanList>
          </AboutSection>
        </Content>
      </Container>
    </>
  );
};

export default About;
